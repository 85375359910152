
import Joi from 'joi';
import { useRouter } from 'vue-router';
import { defineComponent, ref, onMounted } from 'vue';
import {
  IonContent,
  IonPage,
  IonIcon,
  IonButton,
  IonLabel,
  IonFooter,
  IonItem,
} from '@ionic/vue';
import DlvrInput from '@/components/DlvrInput.vue';
import { validateFields } from '@/lib/forms';
import { loading, openToast } from '@/composables/ionicControllers';

import { chevronBackOutline } from 'ionicons/icons';

import { auth } from '@/store';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    DlvrInput,
    IonButton,
    IonIcon,
    IonLabel,
    IonFooter,
    IonItem,
  },
  setup() {
    const resetToken = ref('');
    onMounted(async () => {
      const urlSearchParams = new URLSearchParams(
        window.location.search,
      );
      const params = Object.fromEntries(urlSearchParams.entries());
      resetToken.value = params.token;
      const userToken: any = localStorage.getItem('user-token');
      if (userToken) {
        await router.push('/app');
      }
    });
    const { resetPassword } = auth;

    const password = ref('');
    const confirmPassword = ref('');

    const router = useRouter();

    const validationRules = {
      password: Joi.string()
        .min(4)
        .required(),
      confirmPassword: Joi.string()
        .min(4)
        .required(),
    };

    const schema = Joi.object(validationRules);

    const onSend = async () => {
      if (!resetToken.value) {
        await openToast('No token provided');
        return;
      }
      const { error, value } = validateFields(
        schema,
        () => ({
          password: password.value,
          confirmPassword: confirmPassword.value,
        }),
        false,
      );

      if (error) {
        console.log('error: ', error);
      } else {
        const loader = await loading();
        await loader.present();

        const response: any = await resetPassword(
          value.password,
          value.confirmPassword,
          resetToken.value,
        );

        if (!response.ok) {
          await openToast(response?.message);
          await loader.dismiss();
        } else {
          await loader.dismiss();
          await openToast(
            response?.message || 'Password reset Successful',
          );
          await router.push('/app');
        }

        await loader.dismiss();
      }
    };
    return {
      chevronBackOutline,
      validationRules,
      router,
      password,
      confirmPassword,
      onSend,
    };
  },
});
